import { createSlice } from '@reduxjs/toolkit';

import Api from 'api';
import { AuthService } from 'services/AuthService';
import { updateBetData } from './bets';

import { sseOrdersConnect } from './sse';

export const ORDERS_TYPES = {
  LAY: 'LAY',
  BACK: 'BACK',
};

/*
 *
 *
 * Fix dependancy cycle
 * TO BE REFACTORED
 *
 */

function replacePlaceholders(text, args) {
  return text.replace(/\{\{(\d+)\}\}/g, (match, index) => {
    const replacement = args[index] !== undefined ? args[index] : match;
    return replacement;
  });
}

function getTranslated(phrase, args, store) {
  const translationJsonObject = store?.config?.appConfig?.dictionary;

  const langObj = translationJsonObject.find((obj) => obj.phrase === phrase);
  const langCode = store?.config?.appConfig?.activeLangCode || 'en';

  if (langObj) {
    if (!langCode || langCode === '') {
      // checking if langCode is provided! If not then it will use the default language from the json.
      return `${phrase}`;
    }
    const translationText = langObj[langCode];
    if (translationText) {
      // checking is we have available  translation for given language code if not we will just return the phrase.
      if (args && args.length !== 0) {
        // checking if arguments are provided if not we will return the text with placeholder "{{{0}}}, {{{1}}} etc."
        return replacePlaceholders(translationText, args);
      }
      return `${translationText}`;
    }
    return `${phrase}`;
  }
  return `${phrase}`;
}

const httpRqErrorParser = (error, store, unhandled = null) => {
  if (error.response.data.errors) {
    if (Array.isArray(error.response.data.errors)) {
      const res = { ...error };

      if (
        error.response.data.errors[0].toString().includes('Max bet exceeded')
      ) {
        res.response.data.errors[0] = getTranslated(
          'httpRqErrorMaxBet',
          [error.response.data.maxBet],
          store
        );
      }

      if (
        error.response.data.errors[0]
          .toString()
          .includes('Max exposure exceeded')
      ) {
        res.response.data.errors[0] = getTranslated(
          'httpRqErrorMaxExposure',
          [error.response.data.maxExposure],
          store
        );
      }

      return res.response.data;
    }
    return error.response.data;
  }

  if (unhandled) {
    return {
      success: false,
      errors: unhandled,
    };
  }
  return {
    success: false,
    errors: [error.message],
  };
};

const testGetState = () => (dispatch, getState) => {
  return getState() || {};
};

// EOF dependancy cycle fix

export const ORDER_MODIFICATION_PROP = {
  edit: 0,
  odds: 1,
  stake: 2,
  persistency: 3,
};

export const createOrder = async (bet, dispatch) => {
  const {
    selectionId,
    price,
    stake,
    betType,
    topMarketIdentifier: marketId,
    handicap,
  } = bet;

  try {
    const { data } = await Api.post(`order/create`, {
      side: betType,
      selectionId,
      price,
      size: stake,
      marketId,
      handicap,
    });
    dispatch(updateBetData(data, bet));
  } catch (e) {
    const store = dispatch(testGetState());
    dispatch(updateBetData(httpRqErrorParser(e, store), bet));
  }
};

export const createOrders = (bets, dispatch) => {
  return Promise.all(bets.map((bet) => createOrder(bet, dispatch)));
};

const initialState = {
  data: [],
  modifiedOrders: {},
  processingOrders: true,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    addOrders: (state, action) => {
      state.data = action.payload;
      state.processingOrders = false;
    },
    setOrderInProcess: (state, action) => {
      state.processingOrders = action.payload;
    },
    modifyOrder: (state, action) => {
      const { order, modificationProp, newValue } = action.payload;
      /* if (
        Object.keys(order.actions).filter(
          (theaction) => order.actions[theaction] === true
        ).length
      ) {
        state.modifiedOrders[order.betIdentifier] = order;
      } else {
        delete state.modifiedOrders[order.betIdentifier];
      } */
      const mOrder = state.modifiedOrders[order.betIdentifier] || {
        order,
        data: { ...order },
        actions: {},
      };

      if (modificationProp === ORDER_MODIFICATION_PROP.edit) {
        mOrder.editBetMode = newValue;
      }

      if (modificationProp === ORDER_MODIFICATION_PROP.odds) {
        if (Number(newValue) !== Number(order.odds)) {
          mOrder.oddsChanged = true;
          mOrder.actions.updateOdds = true;
        } else {
          mOrder.oddsChanged = false;
          mOrder.actions.updateOdds = false;
        }
        mOrder.data.odds = newValue;
      }

      if (modificationProp === ORDER_MODIFICATION_PROP.stake) {
        if (Number(newValue) !== Number(order.stake)) {
          mOrder.stakeChanged = true;
          mOrder.actions.updateStake = true;
        } else {
          mOrder.stakeChanged = false;
          mOrder.actions.updateStake = false;
        }
        mOrder.data.stake = newValue;
      }

      if (modificationProp === ORDER_MODIFICATION_PROP.persistency) {
        if (newValue !== (order.persistenceType !== 'LAPSE')) {
          mOrder.persistencyChanged = true;
          mOrder.actions.updatePers = true;
        } else {
          mOrder.persistencyChanged = false;
          mOrder.actions.updatePers = false;
        }
        mOrder.data.persistenceType = newValue ? 'PERSIST' : 'LAPSE';
      }

      if (
        mOrder.oddsChanged ||
        mOrder.stakeChanged ||
        mOrder.persistencyChanged ||
        mOrder.editBetMode
      ) {
        state.modifiedOrders[order.betIdentifier] = mOrder;
      } else {
        delete state.modifiedOrders[order.betIdentifier];
      }
    },
    clearModifiedOrderById: (state, action) => {
      delete state.modifiedOrders[action.payload];
    },
    clearModifiedOrders: (state) => {
      state.modifiedOrders = {};
    },
  },
});

export const {
  addOrders,
  setOrderInProcess,
  modifyOrder,
  clearModifiedOrders,
  clearModifiedOrderById,
} = ordersSlice.actions;
export const ordersReducer = ordersSlice.reducer;

export const getOrders = () => async (dispatch, getState) => {
  if (!AuthService.userToken()) {
    return;
  }

  dispatch(setOrderInProcess(true));
  const { data } = await Api.get(`order/openOrders`);
  const unmatched = data.filter(
    (el) =>
      el.sizeRemaining > 0 ||
      (el.sizeRemaining === 0 &&
        el.sizeMatched === 0 &&
        el.sizeCancelled === 0 &&
        el.sizeLapsed === 0 &&
        el.sizeVoided === 0)
  );
  const eventsIds = unmatched.map((el) => {
    return el.marketIdentifier;
  });
  if (JSON.stringify(getState?.()?.orders?.data) !== JSON.stringify(data)) {
    dispatch(sseOrdersConnect('sse-orders', eventsIds));
  }
  dispatch(addOrders(data));
};
